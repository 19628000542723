<template>
  <div class="row">
    <div class="col-4">
      <b-form-group>
        <label for="date">
          Data
        </label>
        <date-picker
          id="date"
          v-model="form.date"
          @change="checkSnapDate"
          :disabled-date="disableDates"
          :key='dateKey'
          format="dddd[,] DD [de] MMMM"
          placeholder="Selecione uma data"
          :clearable="false"
          :lang="langDatePicker"
          class="full"
          :class="{'disabled': isDisabled() }"
          :disabled="this.disabled"
        />
        <div v-if="validated && !form.date" class="custom-invalid-feedback">Campo obrigatório</div>
        <div v-else-if="validated && this.disableDates(form.date, false) && !form.id" class="custom-invalid-feedback">
          Médico ou sala indisponível nesta data
          <br>Verifique as configurações da agenda
        </div>
      </b-form-group>
    </div>
    <div class="col-2">
      <TimeSelect
        required
        :validated="validated"
        label="Horário início"
        v-model="form.startTime"
        @input="updateEndTime"
        @change="forceReload"
        :key='startKey'
        :date="this.startTimeOptionsComputed.length > 0 && !form.id ? setStartTime(form.date, this.startTimeOptionsComputed) : null"
        :options="this.startTimeOptionsComputed"
        :checkBusyTime="this.checkBusyTime"
        :firstTime="firstTime"
        :lastTime="lastTime"
        :disabled="this.disabled"
      />
      <div v-if="startTimeOptions?.length && !isValidStartTime()" class="custom-invalid-feedback">
        Médico ou sala indisponível neste hórario
      </div>
    </div>
    <div class="col-2">
      <TimeSelect
        required
        :validated="validated"
        label="Horário fim"
        v-model="form.endTime"
        @change="forceReload"
        :options="endTimeOptionsComputed"
        :checkBusyTime="checkBusyTime"
        :key='endKey'
        :date="this.startTimeOptionsComputed.length > 0 && !form.id ? setEndTime(form.date, this.startTimeOptionsComputed) : null"
        :firstTime="firstTime"
        :lastTime="lastTime"
        :startTime="form.startTime"
        :disabled="this.disabled"
      />
      <div v-if="endTimeOptions?.length && !isValidEndTime()" class="custom-invalid-feedback">
        Médico ou sala indisponível neste hórario
      </div>
    </div>
    <div class="col-4">
      <b-form-group>
        <label class="type-label" for="date">
          Tipo agendamento
          <Icon v-if="checkBusyTime(form.startTime)" tooltip="Este paciente está em encaixe, pois foi agendado em um horário indisponível.">
            <Info id="snap-info-tooltip" class="icon" />
          </Icon>
        </label>
        <multiselect
          class="with-border type"
          @select="selectType"
          label="value"
          track-by="value"
          v-model="selectedType"
          :options="appointmentTypeOptions"
          :showLabels="false"
          :allowEmpty="false"
          placeholder=""
          :disabled="isDisabled()"
        >
        <template slot="caret">
          <div class="chevron">
            <ChevronDown />
          </div>
        </template>
        <template slot="noOptions">
          Nenhuma opção
        </template>
        <template slot="noResult">
          Nenhum resultado
        </template>
        </multiselect>
        <div v-if="validated && !form.type" class="custom-invalid-feedback">Campo obrigatório</div>
      </b-form-group>
    </div>

    <div class="col-12" v-if="form.type === 'Encaixe'">
      <b-form-group>
        <label for="date">
          Motivo do encaixe (obrigatório)
        </label>
        <input
          autocomplete="off"
          v-model="form.overlap_reason"
          class="form-control reason"
          :disabled="isDisabled()"
        />
        <div v-if="validated && !form.overlap_reason" class="custom-invalid-feedback">Campo obrigatório</div>
      </b-form-group>
    </div>
  </div>
</template>
<script>
import { getCurrentClinic } from '@/utils/localStorageManager';
import moment from 'moment';
import ChevronDown from '@/assets/icons/chevron-down.svg'
import Info from '@/assets/icons/info.svg'
import TimeSelect from '@/components/Schedule/TimeSelect'
import Icon from '@/components/General/Icon'
import dateAndTimeModuleMixin from '@/mixins/dateAndTimeModuleMixin'
import { canCreateAppointmentTypeFitting } from '@/utils/permissionsHelp'

export default {
  components: {
    TimeSelect,
    ChevronDown,
    Info,
    Icon,
  },
  props: {
    form: Object,
    updateForm: Function,
    validated: Boolean,
    disabled: {
      type: Boolean,
      default: false
    },
    onRenderStartTime: String 
  },
  mounted() {
    this.appointmentTypeOptions = [
      {
        value: 'Agendamento',
        $isDisabled: false
      },
      { value: 'Reagendamento', $isDisabled: false },
      { value: 'Encaixe', $isDisabled: !this.canCreateAppointmentTypeFitting }
    ]
    this.getScheduleConfig();
    this.eventHub.$on('appointment-modal-date', date => {
      setTimeout(() => {
        this.form.date = date
      }, 3000);
    });
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      startDay: null,
      endDay: null,
      durationTime: null,
      startKey: 0,
      endKey: 0,
      dateKey: 0,
      busyTime: [],
      langDatePicker: {
        formatLocale: {
          weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
        },
      },
      firstTime: '',
      lastTime: '',
      appointmentTypeOptions: [],
      selectedType: { value: this.form.type, $isDisabled: false },
    }
  },
  mixins: [dateAndTimeModuleMixin('form', 'clinic')],
  computed: {
    changed() {
      if(!this.onRenderStartTime){
        return false
      } 
      return this.form.id && this.form.startTime !== this.onRenderStartTime
    },
    canCreateAppointmentTypeFitting
    
  },
  methods: {
    moment,
    isDisabled() {
      return (!this.form.professional && !this.form.room) || this.disabled;
    },
    forceReload() {
      // this.startKey += 1
      // this.endKey += 1
      // this.dateKey += 1
      // return 1
    },
    setStartTime(value, possibleValues){
      let time = this.moment(value, 'HH:mm');
      let i = 0;
      let i1 = 0;
      if (value) {
        const formatedTime = time.format("HH:mm");
        if (formatedTime === '00:00') {
          time = time.add(8, 'hours')
        }
        do {
          i++;
          if(this.checkBusyTime(possibleValues[i1].value )) i1++
        }
        while (i < possibleValues.length);
        if (possibleValues.length > 0 && possibleValues[i1] && possibleValues[i1].value > formatedTime) {
          time = this.moment(possibleValues[i1].value, 'HH:mm');
        } else  if(!possibleValues[i1]) {
          time = this.moment(possibleValues[0].value, 'HH:mm');
        }

        return time.toDate()
      }
    },
    setEndTime(value, possibleValues){
      const durationTime = this.getDurationFromStartTime(value);
      let time = this.moment(value, 'HH:mm');
      let i = 0;
      let i1 = 0;
      if (value) {
        const formatedTime = time.format("HH:mm");
        if (formatedTime === '00:00') {
          time = time.add(8, 'hours').add(durationTime, 'minutes')
        }
        if (possibleValues.length > 0 && possibleValues[0].value > formatedTime) {
          time = this.moment(possibleValues[0].value, 'HH:mm');
        }
        do {
          i++;
          if(this.checkBusyTime(possibleValues[i1].value )) i1++
        }
        while (i < possibleValues.length);
        if (possibleValues.length > 0 && possibleValues[i1] && possibleValues[i1].value > formatedTime) {
          time = this.moment(possibleValues[i1].value, 'HH:mm');
        } else if(!possibleValues[i1]) {
          time = this.moment(possibleValues[0].value, 'HH:mm');
        }
        time = time.add(durationTime, 'minutes')
        return time.toDate()
      }
    },
    fetchFirstSelectableTime(value) {
      return value ? this.startTimeOptions.find(startTime => !this.checkBusyTime(value)) : '';
    },
    updateEndTime(value){
      const durationTime = this.getDurationFromStartTime(value);
      this.form.endTime = this.moment(value, 'HH:mm').add(durationTime, 'minutes').format('HH:mm');
      this.generateIntervals();
      // bug on execute insertFirstAllowedTime function
      // !this.form.id && this.insertFirstAllowedTime();
    },
    generateIntervals() {
      if (!this.form.date) return;

      const hasOutage = this.outages.some(outage =>
        moment(this.form.date).isBetween(this.moment(outage.start).startOf('day'), this.moment(outage.end).endOf('day'), null, '[]')
      );

      if(!this.periods || !this.periods.length || hasOutage) {
        this.startTimeOptions = [];
        this.endTimeOptions = [];
        return;
      }
      const startHours = [];
      const endHours = [];
      const _endTime = this.periods.map(period => {
        return period.endDay;
      });

      this.periods.forEach(period => {
        const start = this.moment(period.startDay, 'HH:mm');
        const target = this.moment(period.endDay, 'HH:mm');

        // decrement time to 23:59 if end time is 00:00
        if (target.format('HH:mm') === '00:00') {
          target.endOf('day');
        }

        while (start.isSameOrBefore(target)) {
          if(!_endTime.includes(start.format('HH:mm'))){
            startHours.push(start.format('HH:mm'));
              start.add(period.durationTime, 'minutes');
            if(!this.form.startTime || this.moment(this.form.startTime, 'HH:mm').isSameOrBefore(start)) {
              endHours.push(start.format('HH:mm'));
            }
          }
        }
      });

      this.startTimeOptions = [...new Set(startHours)].sort((a,b) => {
        return moment(a, 'HH:mm') - moment(b, 'HH:mm')
      });
      this.endTimeOptions = [...new Set(endHours)].sort((a,b) => {
        return moment(a, 'HH:mm') - moment(b, 'HH:mm')
      });

      this.startTimeOptions.pop();
      this.endTimeOptions.pop();

      this.firstTime = this.startTimeOptions[0];
      this.lastTime = this.endTimeOptions.at(-1);

      if (!this.form.startTime && this.firstTime) {
        this.form.startTime = this.firstTime;
      }

      if (!this.form.endTime && this.lastTime) {
        this.form.endTime = this.lastTime;
      }

       this.changeFormType()

      this.filterPastHours()
    },
    filterPastHours() {
      if (this.moment(this.form.date).isBefore(this.moment(), 'day')) {
        this.startTimeOptions = [];
        this.endTimeOptions = [];
      }
    },
    changeFormType() {
      if(this.form.startTime && this.checkBusyTime(this.form.startTime) && (this.form.type === 'Agendamento' || this.form.type === 'Reagendamento' )) {
        this.form.type = 'Encaixe'
      } else if (!this.checkBusyTime(this.form.startTime) && this.form.id && this.changed && this.form.type !== 'Reagendamento') {
        this.form.type = 'Reagendamento'
      } else if (!this.checkBusyTime(this.form.startTime) && !this.form.id) {
        this.form.type = 'Agendamento'
      }
    },
    insertFirstAllowedTime() {
      if (!this.form.startTime) {
        this.generateIntervals();
        return;
      }
      
      const startTime = this.startTimeOptions.find(startTime => !this.checkBusyTime(startTime));
      if (startTime) {
        this.form.startTime = startTime;
        const durationTime = this.getDurationFromStartTime(startTime);
        this.form.endTime = this.moment(startTime, 'HH:mm').add(durationTime, 'minutes').format('HH:mm');
        this.generateIntervals();
      }
    },
    getDurationFromStartTime(startTime) {
      const selectedTime = moment('2024-01-01 ' + this.form.startTime)

      const period = this.periods?.find(period => {
        const startInterval = moment('2024-01-01 ' + period.startDay)
        const endInterval = moment('2024-01-01 ' + period.endDay)
        return selectedTime.isBetween(startInterval, endInterval, null, '[]')
      })

      return period?.durationTime ? period.durationTime : this.durationTime
    },
    checkSnapDate() {
      setTimeout(() => {
        if(this.checkBusyTime(this.form.startTime)){
          this.form.type = 'Encaixe'
        } else if(this.form.id){
          this.form.type = 'Reagendamento'
        }
      }, 1000)
    },
    clearData() {
      this.form.date = null;
      this.form.startTime = null;
      this.form.endTime = null;
      this.form.type = null;
      this.form.overlap_reason = null;
    },
    selectType(type) {
      this.form.type = type.value
      // Esta função estava ocasionando erro de startTime vazio ao tentar salvar um agendamento após a verificação de horário já ocupado
      // Foi mantida em comentário para análise e eventual rollback
      
      // if (!['Agendamento', 'Reagendamento'].includes(this.form.type)) return
      // this.form.startTime = null
      // this.form.endTime = null
    },
    isValidStartTime(){
      const firstStartTime = this.startTimeOptions[0]
      const lastEndTime = this.endTimeOptions[this.endTimeOptions.length -1]

      return this.form.startTime >= firstStartTime && this.form.startTime <= lastEndTime
    },
    isValidEndTime(){
      const firstStartTime = this.startTimeOptions[0]
      const lastEndTime = this.endTimeOptions[this.endTimeOptions.length -1]

      return this.form.endTime >= firstStartTime && this.form.endTime <= lastEndTime
    },
  },
  watch: {
    'form.professional': async function(doctor, oldDoctor) {
      if ((!doctor && !oldDoctor)
      || (doctor && oldDoctor && doctor.id === oldDoctor.id)) return;      
      this.startTimeOptions = [];
      this.endTimeOptions = [];
      !doctor && !this.form.id && this.clearData();
      await this.getScheduleConfig();
      if(this.form.date) await this.getBusyDateTimes(this.form.date);
      this.$emit('disabledDate', this.disableDates(this.form.date, false))
    },
    'form.room': async function(room, oldRoom) {
      if ((!room && !oldRoom) || (room && oldRoom && room?.id === oldRoom.id)) return;
      !room && !this.form.id && this.clearData();
      await this.getScheduleConfig();
      if(this.form.date) await this.getBusyDateTimes(this.form.date);
      this.$emit('disabledDate', this.disableDates(this.form.date, false))
    },
    'form.date': async function(date, oldDate) {
      if (!this.moment(date).isSame(this.moment(oldDate)))
        await this.getBusyDateTimes(date);
      this.$emit('disabledDate', this.disableDates(date, false))
    },
    'form.startTime': function () {
      this.changeFormType()
      this.$emit('disabledDate', this.disableDates(this.form.date, false))
    },
    'form.type': function () {
      this.selectedType = { value: this.form.type, $isDisabled: false }
    }
  }
}
</script>
<style lang="scss" scoped>
  .schedule-time {
    width: 100%;
    border-bottom: 1px solid #D9DFF2;
    margin: 20px 0px;
    .schedule-title {
      color:#304388;
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 10px;
    }
    .mx-datepicker {
      width: 100%;
      .mx-input {
        border: 1px solid #C6CEEB;
        border-radius: 8px;
      }
    }
    .times {
      display: flex;
      width: 100%;
      justify-content: center;
      height: 38px;
      .times-separator {
        color: #8696AC;
        font-weight: 400;
        font-size: 16px;
        padding: 0px 1px;
        line-height: 50px;
      }
      .period {
        width: 80px !important;

        ::-webkit-scrollbar {
          width: 8px;
        }

        ::-webkit-scrollbar-thumb {
          background: #C6CEEB;
          border-radius: 100px;
        }

        .multiselect__tags {
          border: none !important;
        }

        .multiselect__option {
          padding: 0px;

          .option {
            padding: 12px 16px;

            &.busy {
              padding: 12px 0px 12px 5px;
              color: #FBA59D;

              .fc-badge {
                width: 6px;
                height: 6px;
                border-radius: 8px;
                border: 1px solid #525c7a;
                margin-right: 5px;
                border-color: #F63220;
                background-color: #F63220;
                opacity: 0.8;
                margin-top: 10px;
                float: left;
              }
            }
          }
        }

        &.multiselect--disabled {
          opacity: 1;
          .multiselect__tags {
            background-color: #ededed;
          }
        }
      }
    }
    .multiselect {
      border: 1px solid #C6CEEB;
      border-radius: 8px;
      height: 38px;

      .multiselect__tags{
        height: 38px;
      }

      .multiselect__single {
        color: var(--type-active);
        font-size: 16px;
        font-weight: 400;
      }

      &.multiselect--disabled {
        opacity: 1;
        .multiselect__tags {
          background-color: #ededed !important;
        }
      }
    }
    .forced-snap {
      height: 38px;
      padding: 15px 0px 15px 15px;
      background-color: #F4F5FB;
      border: 1px solid #C6CEEB;
      border-radius: 8px;
      font-family: Nunito Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      color: #525C7A;
      display: flex;
      justify-content: space-between;

      .icon {
        float: right;
        cursor: pointer;
      }
    }
    .type {
      width: 100%;
      text-transform: capitalize;
      .multiselect__option {
        text-transform: capitalize;
      }
      &.multiselect--disabled {
        opacity: 1;
        .multiselect__tags {
          background-color: #ededed;
        }
      }
    }
    &.hasSnap {
      border-bottom: 0px !important;
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
  }
  .disabled-text {
    margin-top: 5px;
    font-size: 14px;
    color: var(--orange);
  }
  .snap {
    width: 100%;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #D9DFF2;

    .reason {
      width: 100%;
    }

    .reason::placeholder {
      font-family: Nunito Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: #8696AC;
    }
  }
  .type-label {
    display: flex;
    align-items: center;
    gap: 5px;
  }
</style>
