<template>
  <div class="time-wrapper">
    <b-form-group>
      <label>
        {{ label }}
        <span v-if="!required" class="help">(Opcional)</span>
      </label>
      <multiselect
        class="with-border"
        :ref="`timeRef`"
        :value="timeSelected"
        :options="options"
        :showLabels="false"
        :searchable="true"
        :allowEmpty="false"
        :showNoResults="false"
        placeholder=""
        @select="time = $event?.value || $event"
        :disabled="disabled"
        @search-change="updateSearchQuery"
      >
        <template slot="caret" slot-scope="props">
          <div class="search">{{props.option}}</div>
        </template>

        <template slot="option" slot-scope="props">
          <div
            :class="{ option: true, selected: (props.option.value === parseTime(time)), busy: checkBusy(props.option), unbusy: props.option.canEdit }"
            @click="onSelect(props.option.value)"
          >
            <div :class="{ 'fc-badge': true, 'badge-show': checkBusyTime(props.option.value) && !props.option.$isDisabled && !props.option.canEdit}" />
            <div class="d-flex justify-content-start">
              <PadLock class="icon" v-if="props.option.$isDisabled" />
              <OpenPadLock class="icon" v-else-if="props.option.canEdit" />
              {{ props.option?.value || props.option }}
            </div>
          </div>
        </template>

        <template slot="singleLabel" slot-scope="props">
          <span>{{ props.option?.value ?? props.option }}</span>
        </template>

        <template slot="afterList" v-if="searchQuery.match(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/) && !options.includes(searchQuery)">
          <li @click="selectCustomTime">
            <div class="multiselect__option">
              {{ searchQuery }}
            </div>
          </li>
        </template>

        <template slot="noOptions">
          Médico ou sala indisponível nesta data.
        </template>
      </multiselect>
      <div v-if="validated && !time" class="custom-invalid-feedback">Campo obrigatório</div>
    </b-form-group>
  </div>
</template>
<script>
export default {
  props: {
    label: String,
    value: String,
    options: { type: Array, required: true },
    checkBusyTime: Function,
    disabled: Boolean,
    required: Boolean,
    validated: Boolean,
    date: Date,
    firstTime: String,
    lastTime: String,
    startTime: String,
    stringTime: String
  },
  components: {
    PadLock: () => import('@/assets/icons/padlock.svg'),
    OpenPadLock: () => import('@/assets/icons/open-padlock.svg'),
  },
  data() {
    return {
      time: null,
      showOptions: false,
      searchQuery: '',
      optionSugested: null,
      fixInput: 0
    }
  },
  computed: {
    timeSelected() {
      const selectedTime = this.date ? this.date.toTimeString().substr(0,5) : (this.stringTime ?? '') ;
      return this.options.find(option => option.value === this.time) || this.time || selectedTime
    },
    disabledTimes() {
      return this.options.filter(option => option.$isDisabled)
    },
  },
  methods: {
    checkBusy(option) {
      return this.checkBusyTime(option.value) && !option.canEdit || option.$isDisabled
    },
    handleFocus(show) {
      !show &&
        this.time &&
        this.parseTime(this.time) &&
        this.onSelect(this.parseTime(this.time))

      setTimeout(() => {
        this.showOptions = show
      }, 200)
    },
    onSelect(option) {
      this.time = option
      this.$emit('input', option);
    },
    selectCustomTime() {
      this.time = this.searchQuery;
      this.$emit('input', this.searchQuery);
      this.$refs.timeRef.deactivate();
    },
    parseTime(val) {
      if (!val) return null
      let time = val.match(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, '')
      if (time) return time[0]
      time = parseInt(val)
      if (time) return `${String(time).padStart(2, '0')}:00`
      return null
    },
    gotToOption(option) {
      if (!this.showOptions || !option || !this.options.some(opt => opt === option)) return;

      const [element] = this.$refs[`time_${option}`]
      const options = this.$refs.options
      options.scrollTo({
        top: element.offsetTop - 48,
        left: 0,
        behavior: 'smooth'
      })
    },
    updateSearchQuery(query) {
      if (this.isInvalidHour(query)) return
      this.searchQuery = query;
    },
    isInvalidHour(hour) {
      const hourMoment = this.moment(hour, 'HH:mm')
      const lastDisabled = this.disabledTimes.at(-1)
      const isBetweenDisabled = this.disabledTimes.some(option => hourMoment.isBetween(this.moment(option.value, 'HH:mm'), this.moment(lastDisabled.value, 'HH:mm')))

      return hourMoment.isBefore(this.moment(), 'day') || !hourMoment.isBetween(this.moment(this.firstTime, 'HH:mm'), this.moment(this.lastTime, 'HH:mm')) || isBetweenDisabled
    }
  },
  watch: {
    value: {
      handler(val) {
        this.time = val
      },
      immediate: true
    },
    date: {
      handler(val) {
        if (this.fixInput < 1 && val != null){
          this.value = this.date ? val.toTimeString().substr(0,5) : ''
          this.$emit('input', val.toTimeString().substr(0,5));
          this.fixInput = 1
        }
      },
      immediate: true
    },
    timeSelected(newValue) {
      if (typeof newValue === 'object' && newValue?.value) {
        this.time = String(newValue.value)
      } else {
        this.time = String(newValue)
      }
    },
    time(val) {
      const time = this.parseTime(val)
      this.gotToOption(time)
      if(!this.startTime) {
        this.$emit('change')
      }
      if(val && this.startTime){
        let newTime = val
        const intervalOptions = this.options.slice(this.options.indexOf(this.startTime), this.options.indexOf(val))
        intervalOptions.forEach(hour => {
          if(newTime === val && this.checkBusyTime(hour)) {
            this.$toast.warning('Não é possível agendar em horários com agendamento, selecionando horário final anterior ao agendamento já existente!')
            newTime = hour
          }
        });
        this.time = newTime
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .time-wrapper {
    position: relative;
  }
  .option {
    width: 100%;
    display: inline-flex;
    align-items: center;
    gap: 5px;
    padding: 5px 5px;
    text-align: left;
    cursor: pointer;
    &:hover {
      background: var(--neutral-100);
    }
  }
  .selected {
    background: var(--neutral-100);
  }
  .busy {
    color: #FBA59D;
    font-weight: 600;
  }

  .unbusy {
    color: var(--states-success) !important;
    font-weight: 600;
  }
  .fc-badge {
    width: 6px;
    height: 6px;
    border-radius: 8px;
    opacity: 0.8;
  }
  .badge-show {
    border: 1px solid #F63220;
    background-color: #F63220;
  }
</style>
