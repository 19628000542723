import moment from "moment";
import Vue from "vue";
import { canCreateAppointmentTypeFitting } from '@/utils/permissionsHelp'

const dateAndTimeModuleMixin = () => ({
  data: () => ({
    startTimeOptions: [],
    endTimeOptions: [],
    busyDateTimes: [],
    exceptions: [],
    outages: [],
    workTimes: [],
    periods: [],
    isLoading: false
  }),
  computed: {
    canCreateAppointmentTypeFitting,
    reservedDateTimes() {
      return this.busyDateTimes.filter(busyDateTime => busyDateTime?.type);
    },
    startTimeOptionsComputed() {
      const startTimeOptionsMoment = this.startTimeOptions.map(startTimeOption => {
        return moment(startTimeOption, 'HH:mm');
      })

      const intersection = [];
      startTimeOptionsMoment.forEach(startTimeOptionMoment => {
        const index = this.reservedDateTimes.findIndex(reservedDateTime => {
          return startTimeOptionMoment.isBetween(reservedDateTime.start, reservedDateTime.end)
        })

        intersection.push({
          label: startTimeOptionMoment.format('HH:mm'),
          value: startTimeOptionMoment.format('HH:mm'),
          $isDisabled:
            (Boolean(index > -1) &&
              this.isDisabledTime(this.reservedDateTimes[index])) ||
            (!this.canCreateAppointmentTypeFitting &&
              this.checkBusyTime(startTimeOptionMoment.format('HH:mm'))),
          canEdit: Boolean(index > -1) && this.isUnlockDateTime(this.reservedDateTimes[index]),
        })
      })
      return intersection;
    },
    endTimeOptionsComputed() {
      const endTimeOptionsMoment = this.startTimeOptions.map(startTimeOption => {
        return moment(startTimeOption, 'HH:mm');
      })
      const intersection = [];
      endTimeOptionsMoment.forEach(endTimeOptionsMoment => {
        const index = this.reservedDateTimes.findIndex(reservedDateTime => {
          return endTimeOptionsMoment.isBetween(reservedDateTime.start, reservedDateTime.end)
        })
        intersection.push({
          label: endTimeOptionsMoment.format('HH:mm'),
          value: endTimeOptionsMoment.format('HH:mm'),
          $isDisabled: Boolean(index > -1) && this.isDisabledTime(this.reservedDateTimes[index]),
          canEdit: Boolean(index > -1) && this.isUnlockDateTime(this.reservedDateTimes[index]),
        })
      })
      return intersection;
    },
  },
  methods: {
    isDisabledTime(roomReservation) {
      if (this.form?.isReservation) return true;
      const roomId = this.form.room?.id || this.form?.room_id;
      const professionalId = this.form.professional?.id || this.form?.professional_id;
      if (roomReservation?.professional_id) {
        return roomReservation?.professional_id !== professionalId && roomReservation.room_id === roomId
      }
      return roomReservation?.room_id === roomId
    },
    isUnlockDateTime(roomReservation) {
      if (this.form?.isReservation) return false;
      const roomId = this.form.room?.id || this.form?.room_id;
      const professionalId = this.form.professional?.id || this.form?.professional_id;
      return roomReservation?.professional_id && roomReservation?.professional_id === professionalId && roomReservation?.room_id === roomId
    },
    async getScheduleConfig() {
      const roomId = this.form.room?.id || this.form?.room_id;
      const professionalId = this.form.professional ? (this.form.professional.value ? this.form.professional.value : this.form.professional.id) : null;

      const { data } = await Vue.prototype.api.getScheduleConfig(this.clinic.id, roomId, professionalId);
      const { days, exceptions, outages } = data;

      this.workTimes = days.map(workTime => {
        workTime.dayOfWeekInt = this.translateDays(workTime.day);
        return workTime;
      });

      this.exceptions = exceptions;
      this.outages = outages;

      if (this.form.date) {
        await this.getBusyDateTimes(this.form.date);
      }
    },
    async getBusyDateTimes(date) {
      if (!date || this.isLoading) return

      this.isLoading = true

      const { data } = await Vue.prototype.api.getBusyDateTimes({
        'clinic_id': this.clinic.id, 
        'room_id': this.form.room?.id || this.form?.room_id, 
        'professional_id': this.form.professional ? (this.form.professional.value ? this.form.professional.value : this.form.professional.id) : null,
        'equipament_id': this.form.equipment?.id,
        'date': date
      });

      const { periods, durationTime, busyDateTimes } = data;

      this.busyDateTimes = busyDateTimes.reduce((acc, busyDateTime) => {
        const start = moment(busyDateTime.start)
        const end = moment(busyDateTime.end)
        while (start.isBefore(end)) {
          acc.push({
            id: busyDateTime.id,
            time: start.clone(),
            start: start.clone(),
            end: end.clone(),
            type: busyDateTime?.type,
            professional_id: busyDateTime?.professional_id,
            room_id: busyDateTime?.room_id,
          })
          start.add(durationTime, 'minutes')
        }
        return acc;
      }, []);
      this.periods = periods;
      this.durationTime = durationTime;
      this.generateIntervals();
      !this.form.id && this.insertFirstAllowedTime();
      this.isLoading = false
    },
    insertFirstAllowedTime() {
      if (this.form?.type === 'Encaixe' || !this.form?.type) return
      
      if (!this.form.startTime) {
        this.generateIntervals();
        return;
      }

      const startTime = this.startTimeOptions.find(startTime => !this.checkBusyTime(startTime));
      if (startTime) {
        this.form.startTime = startTime;
        const durationTime = this.getDurationFromStartTime(startTime);
        this.form.endTime = this.moment(startTime, 'HH:mm').add(durationTime, 'minutes').format('HH:mm');
        this.generateIntervals();
      }
    },
    generateIntervals() {
      if (!this.form.date) return;

      const hasOutage = this.outages.some(outage =>
        moment(this.form.date).isBetween(this.moment(outage.start).startOf('day'), this.moment(outage.end).endOf('day'), null, '[]')
      );

      if (!this.periods || !this.periods.length || hasOutage) {
        this.startTimeOptions = [];
        this.endTimeOptions = [];
        return;
      }

      const startHours = [];
      const endHours = [];

      this.periods.forEach(period => {
        const start = this.moment(period.startDay, 'HH:mm');
        const target = this.moment(period.endDay, 'HH:mm');

        if (target.format('HH:mm') === '00:00') {
          target.endOf('day');
        }

        while (start.isSameOrBefore(target)) {
          startHours.push(start.format('HH:mm'));
          start.add(period.durationTime, 'minutes');
          if (!this.form.startTime || this.moment(this.form.startTime, 'HH:mm').isSameOrBefore(start)) {
            endHours.push(start.format('HH:mm'));
          }
        }
      });

      this.startTimeOptions = [...new Set(startHours)].sort((a, b) => {
        return moment(a, 'HH:mm') - moment(b, 'HH:mm')
      });
      this.endTimeOptions = [...new Set(endHours)].sort((a, b) => {
        return moment(a, 'HH:mm') - moment(b, 'HH:mm')
      });
      this.firstTime = this.startTimeOptions[0];
      this.lastTime = this.endTimeOptions.at(-1);
      this.filterPastHours()
    },
    filterPastHours() {
      if (moment(this.form.date).isBefore(moment(), 'day')) {
        this.startTimeOptions = [];
        this.endTimeOptions = [];
      }
    },
    beforeDate(date, isSelect = true){
      if (!date || isSelect) {
        return false;
      }
      date = moment(date);
      const dateNow = moment();
      dateNow.subtract(1,"h")
      if (date.isBefore(dateNow)) {
        return true
      }
      return false
    },
    disableDates(date, isSelect = true) {
      if (!date) {
        return false;
      }
      date = moment(date);
      const isNotWorkDay = !this.workTimes.some(workTimes =>
        date.weekday() === workTimes.dayOfWeekInt && workTimes.schedule_config_day_intervals.length
      )
      const hasOutage = this.outages.some(outage =>
        date.isBetween(moment(outage.start).startOf('day'), moment(outage.end).endOf('day'), null, '[]')
      );
      const hasException = this.exceptions.some(exception =>
        date.isBetween(
          moment(exception.date_start).startOf('day'),
          moment(exception.date_end).endOf('day'),
          null, '[]')
      )
      const oldDate = date.isBefore(moment.now()) && !date.isSame(moment(), "day")
      if (hasException) return false;
      return isNotWorkDay || hasOutage || oldDate
    },
    checkBusyTime(value) {
      if (!value) return false;
      const date = moment(this.form.date).format('YYYY-MM-DD')
      const time = moment(`${date} ${value}`);

      return this.busyDateTimes.some(busyDateTime => {
        return this.form.id !== busyDateTime.id && (time.isSameOrAfter(busyDateTime.start) && time.isBefore(busyDateTime.end) && !busyDateTime?.type);
      });
    },
    translateDays(day) {
      return {
        'DOMINGO': 0,
        'SEGUNDA-FEIRA': 1,
        'TERCA-FEIRA': 2,
        'QUARTA-FEIRA': 3,
        'QUINTA-FEIRA': 4,
        'SEXTA-FEIRA': 5,
        'SABADO': 6,
      }[day];
    },
  },
});

export default dateAndTimeModuleMixin;
